<template>
  <div class="bg-lg p-5 sm:p-10">
    <tabs
      :items="[
        {
          key: 'post',
          title: $t('counterRecharge.counter_post'),
          active: true,
        },
        { key: 'pre', title: $t('counterRecharge.counter_pre'), active: false },
      ]"
    >
      <template v-slot:post>
        <kd-post-bills />
      </template>
      <template v-slot:pre>
        <kd-bills />
      </template>
    </tabs>
  </div>
</template>

<script>
import Tabs from "../../components/Tabs.vue";
import KdBills from "./KdBills.vue";
import KdPostBills from './KdPostBills.vue';

export default {
  components: {
    KdBills,
    Tabs,
    KdPostBills,
  },
};
</script>

<style scoped>
.bg-lg {
  background-image: url(../../assets/svg/SBEEA1.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
}

@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/dashbIcons/background2lg.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
  }
}
</style>