<template>
  <div class="h-full">
    <toast-message
      :show="toast.show"
      @close="toast.show = false"
      :color="toast.color"
      :message="toast.message"
      style="top: 16px; right: 16px"
    >
      <span slot="icon">
        <component :is="toast.icon" />
      </span>
    </toast-message>
    <toast-message
      :show="billSent"
      @close="showToast = false"
      :message="$t('counterRecharge.bill_sent')"
    />
    <toast-message
      :show="billSentError"
      @close="showToast = false"
      :message="$t('counterRecharge.bill_sent_error')"
    />
    <div class="grid grid-cols-1">
      <!-- <div class="mb-6 flex justify-between font-semibold">
        <span class=" text-xl">{{ $t("bills.title", {count: numbOfBill}) }}</span>
      </div> -->
      <div
        class="col-span-1 grid lg:grid-cols-3 lg:gap-0 gap-0 mb-6 bg-white p-2"
      >
        <div
          class="ffamily-pro bg-white col-span-1 flex items-center m-2"
          style="text-align: left"
        >
          <span>
            <img class="m-2" src="@/assets/images/compteur mini.png" alt="" />
          </span>
          <div class="flex flex-col" style="color: #222f5a">
            <span class="font-bold">{{ $t("bills.number") }}</span>
            <span class="text-4xl text-black font-semibold ffamily-pro">{{
              totalMeter
            }}</span>
          </div>
        </div>

        <div
          class="bg-white col-span-1 flex items-center m-2"
          style="text-align: left"
        >
          <span class="m-2"
            ><img src="../../assets/svg/dashbIcons/dollar.svg" alt=""
          /></span>
          <div class="flex flex-col" style="color: #222f5a">
            <span class="font-bold">{{ $t("meterSeen.total") }}</span>
            <span class="text-4xl text-tex-2 font-semibold ffamily-pro"
              >{{ totalTransactions }} FCFA</span
            >
          </div>
        </div>

        <div
          class="bg-white col-span-1 flex items-center m-2"
          style="text-align: left"
        >
          <span> <Iconcalb class="m-2" /></span>
          <div class="flex flex-col" style="color: #222f5a">
            <span class="font-bold">{{ $t("bills.addDate") }}</span>
            <span
              v-if="counters.length > 0"
              class="text-3xl font-semibold ffamily-pro"
              >{{ lastMeterAddDate }}</span
            >
            <span v-else class="text-3xl font-semibold ffamily-pro"
              ><p>_ _ _</p></span
            >
          </div>
        </div>
      </div>

      <div class="flex justify-between items-center mb-4">
        <div style="min-width: 250px">
          <div
            class="
              flex
              h-search
              items-center
              border
              py-1
              border-kborder-900
              bg-kborder-300 bg-opacity-50
              rounded
            "
          >
            <div class="px-2"> <Iconsearch /></div>
            <input
              :placeholder="$t('meterDash.search')"
              class="w-full text-sm bg-transparent focus:outline-none"
              type="text"
              id="myInput"
              v-model="searchInput"
              @keyup="searchFunction"
            />
          </div>
        </div>
        <calendar-picker @change="onCalendarChange" />
      </div>

      <div v-if="bills.length != 0" class="bg-white col-span-1">
        <table
          id="myTable"
          class="
            w-full
            table-auto
            xl:table-fixed
            text-sm
            border border-solid
            ffamily-pro
          "
        >
          <thead
            class="
              hidden
              lg:table-header-group
              font-bold
              text-left
              border border-bgk-2 border-solid
            "
          >
            <th class="p-3">{{ $t("rechargePay.subscriberNumber") }}</th>
            <th>{{ $t("rechargePay.amount") }}</th>
            <th>{{ $t("homeDash.date") }}</th>
            <th>{{ $t("homeDash.action") }}</th>
          </thead>
          <tbody v-if="!searchInput">
            <tr
              class="hover:bg-bgk-1 border border-bgk-2 border-solid"
              v-for="bill in bills"
              :key="bill._id"
            >
              <td class="flex flex-col p-3">
                <span class="text-sm">{{ bill.subscriber.reference }}</span>
                <span class="text-xs" style="color: #4d4c4c">{{
                  bill.subscriber.name
                }}</span>
              </td>
              <td class="text-right lg:text-left">
                <span>{{ bill.amount }} Fcfa</span><br />
                <span class="lg:hidden">{{ getDate(bill.updatedAt) }}</span>
              </td>
              <td>
                <span class="hidden lg:table-cell">{{
                  getDate(bill.updatedAt)
                }}</span>
              </td>
              <td class="px-2 lg:px-0 w-8 lg:w-auto">
                <div class="lg:flex hidden">
                  <div class="flex items-center">
                    <button
                      class="focus:outline-none mr-2"
                      :title="$t('tooltip.downloadbill')"
                      @click="downloadBill(bill._id)"
                    >
                      <Icondownl />
                    </button>
                    <button
                      class="focus:outline-none mr-2"
                      :title="$t('tooltip.sendbill')"
                      @click="passSendPurchase(bill._id)"
                    >
                      <Iconsend />
                    </button>
                    <see-more>
                      <template v-slot>
                        <div style="font-size: 18px; color: #333">
                          <div v-for="(b, i) in bill.bills" :key="i" class="flex justify-between space-x-4 my-2">
                            <b>{{$t('rechargePay.invoiceOf')}} : {{b.period}}</b>
                            <b style="color: red">{{b.amountToBePaid}} Fcfa</b>
                          </div>
                          <hr>
                          <div class="flex justify-end space-x-4 my-2">
                            <b>Total : </b>
                            <b style="color: red">{{bill.amount}} Fcfa</b>
                          </div>
                        </div>
                      </template>
                    </see-more>
                  </div>
                </div>
                <div class="lg:hidden">
                  <Icondatatabl @click="bill.active = !bill.active" class="" />
                  <div
                    v-show="bill.active == true"
                    @click="bill.active = !bill.active"
                    class="
                      absolute
                      -ml-24
                      bg-white
                      flex flex-col
                      p-4
                      shadow-xs
                      rounded
                    "
                  >
                    <button
                      class="focus:outline-none flex items-center mb-1"
                      @click="downloadBill(bill._id)"
                    >
                      <Icondownl class="mr-3" /><span>{{
                        $t("homeDash.actionDown")
                      }}</span>
                    </button>
                    <button
                      class="focus:outline-none flex items-center mb-1"
                      @click="passSendPurchase(bill._id)"
                    >
                      <Iconsend class="mr-3" /><span>{{
                        $t("homeDash.actionSend")
                      }}</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="searchInput">
            <tr
              class="hover:bg-bgk-1 border border-bgk-2 border-solid"
              v-for="bill in searchBills"
              :key="bill._id"
            >
              <td class="flex flex-col p-3">
                <span class="text-sm">{{ bill.subscriber.reference }}</span>
                <span class="text-xs" style="color: #4d4c4c">{{
                  bill.subscriber.name
                }}</span>
              </td>
              <td class="text-right lg:text-left">
                <span>{{ bill.amount }} Fcfa</span><br />
                <span class="lg:hidden">{{ getDate(bill.updatedAt) }}</span>
              </td>
              <td>
                <span class="hidden lg:table-cell">{{
                  getDate(bill.updatedAt)
                }}</span>
              </td>
              <td class="px-2 lg:px-0 w-8 lg:w-auto">
                <div class="lg:flex hidden">
                  <div class="flex items-center">
                    <button
                      class="focus:outline-none mr-2"
                      @click="downloadBill(bill._id)"
                    >
                      <Icondownl />
                    </button>
                    <button
                      class="focus:outline-none mr-2"
                      @click="passSendPurchase(bill._id)"
                    >
                      <Iconsend />
                    </button>
                  </div>
                </div>
                <div class="lg:hidden">
                  <Icondatatabl @click="bill.active = !bill.active" class="" />
                  <div
                    v-show="bill.active == true"
                    @click="bill.active = !bill.active"
                    class="
                      absolute
                      -ml-24
                      bg-white
                      flex flex-col
                      p-4
                      shadow-xs
                      rounded
                    "
                  >
                    <button
                      class="focus:outline-none flex items-center mb-1"
                      @click="downloadBill(bill._id)"
                    >
                      <Icondownl class="mr-3" /><span>{{
                        $t("homeDash.actionDown")
                      }}</span>
                    </button>
                    <button
                      class="focus:outline-none flex items-center mb-1"
                      @click="passSendPurchase(bill._id)"
                    >
                      <Iconsend class="mr-3" /><span>{{
                        $t("homeDash.actionSend")
                      }}</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-show="pages.length > 1"
          class="p-4 text-right border border-bgk-2"
        >
          <nav>
            <ul>
              <li>
                <button
                  :class="
                    currentPage === pageNumber
                      ? 'bg-red-600 text-white'
                      : 'bg-white'
                  "
                  class="
                    focus:outline-none
                    text-center text-xs
                    rounded-full
                    h-5
                    w-5
                  "
                  v-for="pageNumber in pages"
                  :key="pageNumber.id"
                  @click="gotoPage(pageNumber)"
                >
                  {{ pageNumber }}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div v-else class="bg-white rounded h-statedt">
        <div class="mx-auto" style="height: 300px; width: 300px">
          <img src="@/assets/gifts/dataTableEmpty.gif" alt="" />
        </div>
        <div class="mt-16 text-xl text-center">
          {{ $t("bills.datatableEmpty") }}
        </div>
      </div>
      <div
        v-if="searchInput && searchBills.length == 0"
        class="bg-white rounded h-statedt"
      >
        <div class="mx-auto" style="height: 300px; width: 300px">
          <img src="@/assets/gifts/dataTableEmpty.gif" alt="" />
        </div>
        <div class="mt-16 text-xl text-center">
          {{ $t("meterDash.datatableSearchEmpty") }}
        </div>
      </div>
    </div>
    <KdSendModal
      :isPost="isPost"
      @sent="onSent"
      @success="onSendSuccess"
      @fail="onSendFail"
    />
  </div>
</template>

<script>
import KdSendModal from "./kdSendModal";
import axios from "axios";

import Iconcalb from "../../assets/svg/dashbIcons/calendarbig.svg?inline";
import Icondownl from "../../assets/svg/dashbIcons/downloadblue.svg?inline";
import Iconsend from "../../assets/svg/dashbIcons/sendgreen.svg?inline";
import Iconrefresh from "../../assets/svg/dashbIcons/refresh.svg?inline";
import Icondatatabl from "../../assets/svg/dashbIcons/datatablmb.svg?inline";
import Iconsearch from "../../assets/svg/dashbIcons/search.svg?inline";
import IconSuccess from "../../assets/svg/icons/toascheck.svg?inline";
import IconError from "../../assets/svg/icons/toasterror.svg?inline";
import ToastMessage from "../../components/ToastMessage.vue";
import CalendarPicker from "../../components/CalendarPicker.vue";
import SeeMore from '../../components/SeeMore.vue';
export default {
  components: {
    Iconsearch,
    Iconcalb,
    Icondownl,
    Iconsend,
    Iconrefresh,
    Icondatatabl,
    KdSendModal,
    ToastMessage,
    IconSuccess,
    IconError,
    CalendarPicker,
    SeeMore,
  },

  data() {
    return {
      calendar: false,
      chevron: true,
      active: false,
      checkin: "",
      achats: [],
      page: 1,
      pages: [],
      searchInput: null,
      totalMeter: 0,
      toast: {
        show: false,
        message: "",
        icon: "IconSuccess",
        color: "bg-bgk-3",
      },
      isPost: false,
      postId: null,
      billSent: false,
      billSentError: false,
      lastMeterAddDate: new Date().toLocaleDateString(),
      totalTransactions: 0,
    };
  },
  methods: {
    getDate(value) {
      const v = new Date(value);
      const d = v.toLocaleDateString().split("/").join(".");
      const h = v.getHours() + ":" + v.getMinutes();
      return `${d} à ${h}`;
    },
    downloadBill(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/transactions/download/${id}`)
        .then((response) => {
          window.open(response.data, "_blank");
        });
    },
    gotoPage(pageNumber) {
      this.page = pageNumber;
      this.$store.commit("mutBills", { page: pageNumber });
    },
    setPages() {
      this.pages = [];
      let numberOfpages = Math.ceil(this.numbOfPages);
      for (let index = 1; index <= numberOfpages; index++) {
        this.pages.push(index);
      }
    },
    onCalendarChange(value) {
      const { startDate, endDate } = value;
      this.$store.commit(
        "mutBills",
        startDate === "allTime" ? {} : { startDate, endDate }
      );
    },
    passDeletePurchase(id) {
      this.$store.commit("mutPurchaseId", id);
      this.$store.commit("mutTrashPurchase", true);
    },
    passSendPurchase(id) {
      this.postId = id;
      this.isPost = true;
      this.$store.commit("mutSendPurchase", true);
    },
    onSent(value) {
      if (value.email) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "/notifications/email", {
            email: value.email,
            transactionId: this.postId,
          })
          .then(() => {
            this.billSent = true;
            setTimeout(() => {
              this.billSent = false;
            }, 3000);
          })
          .catch(() => {
            this.billSentError = true;
            setTimeout(() => {
              this.billSentError = false;
            }, 3000);
          });
      }

      if (value.smsTel) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "/notifications/sms", {
            phone: value.countryCode + " " + value.smsTel,
            transactionId: this.postId,
          })
          .then(() => {
            this.billSent = true;
            setTimeout(() => {
              this.billSent = false;
            }, 3000);
          })
          .catch(() => {
            this.billSentError = true;
            setTimeout(() => {
              this.billSentError = false;
            }, 3000);
          });
      }
    },
    async searchFunction() {
      let input = document.getElementById("myInput");
      let text = input.value.toUpperCase();
      console.log(text.length);
      if (text.length > 0) {
        console.log("here");
        await this.$store.commit("mutSearchBills", text);
      } else {
        await this.$store.commit("mutBills");
      }
    },

    onSendSuccess() {
      const toast = {
        show: true,
        message: this.$t("rechargeSuccess.toastMessage"),
        icon: "IconSuccess",
        color: "bg-bgk-3",
      };
      this.toast = toast;
    },

    onSendFail() {
      const toast = {
        show: true,
        message: this.$t("rechargeSuccess.toastErrorMessage"),
        icon: "IconError",
        color: "bg-bgk-6",
      };
      this.toast = toast;
    },
  },

  computed: {
    bills() {
      return this.$store.getters.getBills;
    },
    searchBills() {
      console.log(this.$store.getters.getSearchBills);
      return this.$store.getters.getSearchBills;
    },
    numbOfPages() {
      return this.$store.getters.getNumbOfPages;
    },
    numbOfBill() {
      return this.$store.getters.getNumbOfBill;
    },
    currentPage() {
      return this.page;
    },
    counters() {
      return this.$store.getters.getCounter;
    },
  },
  watch: {
    numbOfPages() {
      this.setPages();
    },
  },
  mounted() {
    this.setPages();
    this.$store.commit("mutBills");
    axios
      .get(process.env.VUE_APP_API_BASE_URL + `/transactions/metrics/user`, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.getAuthUser.token,
        },
      })
      .then((response) => {
        const stats = response.data;
        this.totalMeter = stats.subscribersTotal;
        this.lastMeterAddDate = new Date(
          stats.lastDateOfTheRegisteredSubscriber
        ).toLocaleDateString();
        this.totalTransactions = stats.totalAmount;
      });
  },
};
</script>
