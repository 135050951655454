<template>
  <div class="h-full">
    <toast-message :show="toast.show" @close="toast.show = false" :color="toast.color" :message="toast.message" style="top: 16px; right: 16px;">
      <span slot="icon">
        <component :is="toast.icon" />
      </span>
    </toast-message>
    <div class="grid grid-cols-1">
      <!-- <div class="mb-6 flex justify-between font-semibold">
        <span class=" text-xl">{{ $t("bills.title", {count: numbOfBill}) }}</span>
      </div> -->
      <div class="col-span-1 grid lg:grid-cols-3 lg:gap-0 gap-0 mb-6 bg-white p-2">
        <div class="ffamily-pro bg-white col-span-1 flex items-center m-2" style="text-align: left">
          <span>
            <img class="m-2" src="@/assets/images/compteur mini.png" alt="">
          </span>
          <div class="flex flex-col " style="color: #222F5A;">
            <span class="font-bold ">{{ $t("bills.number") }}</span>
            <span class="text-4xl text-black font-semibold ffamily-pro">{{ counters.length }}</span>
          </div>
        </div>

        <div class=" bg-white col-span-1 flex items-center m-2" style="text-align: left">
          <span class="m-2"><img src="../../assets/svg/dashbIcons/dollar.svg" alt="" /></span>
          <div class="flex flex-col " style="color: #222F5A;">
            <span class="font-bold ">{{ $t("meterSeen.total") }}</span>
            <span class="text-4xl text-tex-2 font-semibold ffamily-pro">{{ totalRecharge }} FCFA</span>
          </div>
        </div>

        <div class="bg-white  col-span-1 flex items-center m-2" style="text-align: left">
          <span>
            <Iconcalb class="m-2" /></span>
          <div class="flex flex-col " style="color: #222F5A;">
            <span class="font-bold ">{{ $t("bills.addDate") }}</span>
            <span v-if="counters.length > 0" class="text-3xl font-semibold ffamily-pro">{{ lastMeterAddDate }}</span>
            <span v-else class="text-3xl font-semibold ffamily-pro"><p>_ _ _</p></span>
          </div>
        </div>
      </div>

      <div>
        <span
          class="flex h-search mb-12 lg:w-1/4 items-center border py-1 border-kborder-900 bg-kborder-300 bg-opacity-50 rounded">
          <span class="px-2">
            <Iconsearch /></span>
          <input :placeholder="$t('meterDash.search')" class="w-full text-sm bg-transparent focus:outline-none" type="text"
            id="myInput" v-model="searchInput" @keyup="searchFunction" />
        </span>
      </div>

      <div v-if="purchases.length != 0" class="bg-white col-span-1">
        <table id="myTable" class="w-full table-auto xl:table-fixed text-sm border border-solid ffamily-pro">
          <thead class=" hidden lg:table-header-group font-bold text-left border border-bgk-2 border-solid">
            <th class="p-3 ">{{ $t("homeDash.meterNumb") }}</th>
            <th>{{ $t("homeDash.metrAmount") }}</th>
            <th>{{ $t("homeDash.date") }}</th>
            <th>{{ $t("homeDash.action") }}</th>
          </thead>
          <tbody v-if="!searchInput" >
            <tr class="hover:bg-bgk-1 border border-bgk-2 border-solid" v-for="purchase in purchases"
              :key="purchase.id">
              <td class="flex flex-col p-3 ">
                <span class="text-sm">{{ purchase.counter }}</span>
                <span class="text-xs" style="color: #4D4C4C">{{
                  purchase.cUser
                }}</span>
              </td>
              <td class="text-right lg:text-left">
                <span>{{ purchase.amount }} Fcfa</span><br />
                <span class="lg:hidden">{{ purchase.date }}</span>
              </td>
              <td>
                <span class="hidden lg:table-cell">{{ purchase.date }}</span>
              </td>
              <td class="px-2 lg:px-0 w-8 lg:w-auto">
                <div class="lg:flex hidden">
                  <div class="flex items-center">
                    <button class="focus:outline-none mr-2" :title="$t('tooltip.downloadbill')"  @click="downloadBill(purchase.transacId)">
                      <Icondownl />
                    </button>
                    <button class="focus:outline-none mr-2" :title="$t('tooltip.sendbill')" @click="passSendPurchase(purchase.transacId)">
                      <Iconsend />
                    </button>
                  </div>
                  <div class="border-l-2 ">
                    <button class="focus:outline-none flex items-center ml-2" :title="$t('tooltip.renew')" @click="renouveller(purchase.counter, purchase.cUser)">
                      <Iconrefresh />
                      <span class="text-sm hidden lg:block font-semibold ml-2"
                        style="color: #0965F6;">{{ $t("homeDash.actionRefresh") }}</span>
                    </button>
                  </div>
                </div>
                <div class="lg:hidden">
                  <Icondatatabl @click="purchase.active = !purchase.active" class="" />
                  <div v-show="purchase.active == true" @click="purchase.active = !purchase.active"
                    class="absolute -ml-24  bg-white flex flex-col p-4 shadow-xs rounded">
                    <button class="focus:outline-none flex items-center mb-1"  @click="downloadBill(purchase.transacId)">
                      <Icondownl class="mr-3" /><span>{{ $t("homeDash.actionDown") }}</span>
                    </button>
                    <button class="focus:outline-none flex items-center mb-1" @click="passSendPurchase(purchase.transacId)">
                      <Iconsend class="mr-3" /><span>{{ $t("homeDash.actionSend") }}</span>
                    </button>
                    <button class="focus:outline-none flex items-center" @click="renouveller(purchase.counter, purchase.cUser)">
                      <Iconrefresh class="mr-3" /><span>{{ $t("homeDash.actionRefresh") }}</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="searchInput" >
            <tr class="hover:bg-bgk-1 border border-bgk-2 border-solid" v-for="purchase in searchPurchases"
              :key="purchase.id">
              <td class="flex flex-col p-3 ">
                <span class="text-sm">{{ purchase.counter }}</span>
                <span class="text-xs" style="color: #4D4C4C">{{
                  purchase.cUser
                }}</span>
              </td>
              <td class="text-right lg:text-left">
                <span>{{ purchase.amount }} Fcfa</span><br />
                <span class="lg:hidden">{{ purchase.date }}</span>
              </td>
              <td>
                <span class="hidden lg:table-cell">{{ purchase.date }}</span>
              </td>
              <td class="px-2 lg:px-0 w-8 lg:w-auto">
                <div class="lg:flex hidden">
                  <div class="flex items-center">
                    <button class="focus:outline-none mr-2"  @click="downloadBill(purchase.transacId)">
                      <Icondownl />
                    </button>
                    <button class="focus:outline-none mr-2" @click="passSendPurchase(purchase.transacId)">
                      <Iconsend />
                    </button>
                  </div>
                  <div class="border-l-2">
                    <button class="focus:outline-none flex items-center" @click="renouveller(purchase.counter, purchase.cUser)">
                      <Iconrefresh />
                      <span class="text-sm hidden lg:block font-semibold ml-2"
                        style="color: #0965F6;">{{ $t("homeDash.actionRefresh") }}</span>
                    </button>
                  </div>
                </div>
                <div class="lg:hidden">
                  <Icondatatabl @click="purchase.active = !purchase.active" class="" />
                  <div v-show="purchase.active == true" @click="purchase.active = !purchase.active"
                    class="absolute -ml-24  bg-white flex flex-col p-4 shadow-xs rounded">
                    <button class="focus:outline-none flex items-center mb-1" @click="downloadBill(purchase.transacId)">
                      <Icondownl class="mr-3" /><span>{{ $t("homeDash.actionDown") }}</span>
                    </button>
                    <button class="focus:outline-none flex items-center mb-1" @click="passSendPurchase(purchase.transacId)">
                      <Iconsend class="mr-3" /><span>{{ $t("homeDash.actionSend") }}</span>
                    </button>
                    <button class="focus:outline-none flex items-center" @click="renouveller(purchase.counter, purchase.cUser)">
                      <Iconrefresh class="mr-3" /><span>{{ $t("homeDash.actionRefresh") }}</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="pages.length > 1" class="p-4 text-right border  border-bgk-2">
          <nav>
            <ul>
              <li>
                <button :class="
                    currentPage === pageNumber
                      ? 'bg-red-600 text-white'
                      : 'bg-white'
                  " class="focus:outline-none  text-center text-xs rounded-full h-5 w-5 "
                  v-for="pageNumber in pages" :key="pageNumber.id" @click="gotoPage(pageNumber)">
                  {{ pageNumber }}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div v-else class="bg-white rounded h-statedt">
          <div class="mx-auto" style="height: 300px; width:300px"><img src="@/assets/gifts/dataTableEmpty.gif" alt=""></div>
          <div class="mt-16 text-xl text-center">{{ $t("bills.datatableEmpty") }}</div>
      </div>
      <div v-if="searchInput && searchPurchases.length == 0" class="bg-white rounded h-statedt">
          <div class="mx-auto" style="height: 300px; width:300px"><img src="@/assets/gifts/dataTableEmpty.gif" alt=""></div>
          <div class="mt-16 text-xl text-center">{{ $t("meterDash.datatableSearchEmpty")}}</div>
      </div>
    </div>
    <KdSendModal @success="onSendSuccess" @fail="onSendFail" />
  </div>
</template>

<script>

import KdSendModal from "./kdSendModal";

import Iconcalb from "../../assets/svg/dashbIcons/calendarbig.svg?inline";
import Icondownl from "../../assets/svg/dashbIcons/downloadblue.svg?inline";
import Iconsend from "../../assets/svg/dashbIcons/sendgreen.svg?inline";
import Iconrefresh from "../../assets/svg/dashbIcons/refresh.svg?inline";
import Icondatatabl from "../../assets/svg/dashbIcons/datatablmb.svg?inline";
import Iconsearch from "../../assets/svg/dashbIcons/search.svg?inline";
import IconSuccess from "../../assets/svg/icons/toascheck.svg?inline";
import IconError from "../../assets/svg/icons/toasterror.svg?inline";
import ToastMessage from '../../components/ToastMessage.vue';
export default {
  components: {
    Iconsearch,
    Iconcalb,
    Icondownl,
    Iconsend,
    Iconrefresh,
    Icondatatabl,
    KdSendModal,
    ToastMessage,
    IconSuccess,
    IconError
  },

  data() {
    return {
      calendar: false,
      chevron: true,
      active: false,
      checkin: "",
      achats: [],
      page: 1,
      pages: [],
      searchInput: null,
      toast : {
          show: false,
          message: "",
          icon: 'IconSuccess',
          color: 'bg-bgk-3'
      }
    };
  },
  methods: {
    downloadBill(transacId){
      this.$store.dispatch('downloadBillRequest', transacId)
      .then(data=>{
          window.open(data, '_blank');
      })
      .catch(erorr => {
        console.log(erorr);
        const toast = {
          show: true,
          message: this.$t("rechargeSuccess.downloadErrorMessage"),
          icon: 'IconError',
          color: 'bg-bgk-6'
        }
        this.toast  = toast
      })
    },
    renouveller(numb, user){
      const meter = {
          cNumb: numb,
          cUser: user
      }
      this.$store.commit('mutSelectedMeter', meter)
      this.$store.commit('mutCheckArrear')
      this.$router.push("/dashboard/recharge");
    },
    getPurchases(page) {
      const data = {
        page: page,
        startDate: 'allTime',
        endDate: 'endAllTime'
      }
      this.$store.commit("mutPurchase", data);
    },
    gotoPage(pageNumber){
      this.page = pageNumber;
      this.getPurchases(pageNumber)
    },
    setPages() {
      this.pages = []
      let numberOfpages = Math.ceil(this.numbOfPages);
      for (let index = 1; index <= numberOfpages; index++) {
        this.pages.push(index);
      }
    },
    passDeletePurchase(id) {
      this.$store.commit("mutPurchaseId", id);
      this.$store.commit("mutTrashPurchase", true);
    },
    passSendPurchase(id) {
       this.$store.commit("mutSendPurchase", true);
      this.$store.commit("mutTransacId", id)
    },
    async searchFunction() {
      let input = document.getElementById("myInput");
      let text = input.value.toUpperCase();
      await this.$store.commit("mutSearchPurchase", text);
     
    },

    onSendSuccess(){
      const toast = {
        show: true,
        message: this.$t("rechargeSuccess.toastMessage"),
        icon: 'IconSuccess',
        color: 'bg-bgk-3'
      }
      this.toast  = toast
    },

    onSendFail() {
       const toast = {
        show: true,
        message: this.$t("rechargeSuccess.toastErrorMessage"),
        icon: 'IconError',
        color: 'bg-bgk-6'
      }
      this.toast  = toast
    }
  },

  computed: {
    purchases() {
      return this.$store.getters.getPurchases;
    },
    searchPurchases() {
      return this.$store.getters.getSearchPurchases;
    },
    numbOfPages(){
      return this.$store.getters.getNumbOfPages;
    },
    numbOfBill(){
      return this.$store.getters.getNumbOfBill;
    },
    currentPage() {
      return this.page;
    },
    counters(){
      return this.$store.getters.getCounter
    },
    totalRecharge(){
      return this.$store.getters.getTotalRecharge
    },
    lastMeterAddDate(){
      const lastMeter = this.counters[0].createdDate
      return lastMeter
    },
  },
  watch: {
    numbOfPages () {
      this.setPages();
    }
  },
  mounted() {
    this.getPurchases(1);
    this.setPages();
  },
};
</script>
