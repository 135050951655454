<template>
  <div class="v-container" ref="cref">
    <div class="flex items-center space-x-2 v-left" @click="toggle">
      <EyeIcon />
      <div class="v-text">
        {{ $t("counterRecharge.view_details") }}
      </div>
    </div>
    <div class="v-dropdown" v-if="isOpen">
      <slot />
    </div>
  </div>
</template>

<script>
import EyeIcon from "@/components/Icons/EyeIcon.vue";
export default {
  components: {
    EyeIcon,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    document.addEventListener("click", (event) => {
      const cref = this.$refs.cref;
      if (cref && !cref.contains(event.target)) {
        this.isOpen = false;
      }
    });
  },
};
</script>

<style scoped>
.v-container {
  position: relative;
}

.v-text {
  font-family: "Source Sans Pro";
  color: #0965f6;
  letter-spacing: -0.1px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

.v-left {
    padding-left: 10px;
    border-left: solid 1px #c4c4c4;
}

.v-dropdown {
  position: absolute;
  top: 100%;
  right: -50px;
  border: 1px solid #cccccc;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  z-index: 999;
  min-width: 400px;
  margin-top: 7px;
}
</style>